import React from 'react';
import { GridRenderCellParams, GRID_CHECKBOX_SELECTION_COL_DEF, GridColDef } from '@mui/x-data-grid-pro';

import { keyBy } from 'lodash';
import { getCurrencyFormat } from '../common';
import { CompanyCell } from './CompanyCell';
import { CommonCell } from './CommonCell';
import { CheckboxHeader } from './CheckboxHeader';
import { CheckboxCell } from './CheckboxCell';
import { CompanyHeader } from './CompanyHeader';
import { CommonHeader } from './CommonHeader';
import { ColumnFilterType, Currency, IColumFilters, IColumnMapper } from '@/types';
import { prepareColumns } from '@/Utils/table';
import { CURRENCIES_SET } from '@/constants';

interface ColumnDefinition {
  // columnFilters?: IColumFilters,
  field: string;
  displayName: string;
  filterType?: ColumnFilterType;
  numericPrecision?: number;
  sortable?: boolean;
  columnMetadata?: string;
}

const DEFAULT_COLUMN_WIDTH = 200;
const MAX_NUMERICAL_DEFAULT_COLUMN_WIDTH = 150;
const MIN_NUMERICAL_DEFAULT_COLUMN_WIDTH = 100;
const APPROX_CHAR_WIDTH = 10;

const getColumnWidth = (field: string, displayName: string, filterType?: ColumnFilterType) => {
  if (field === 'self_firmo_description___') {
    return { width: 340 };
  }

  if (displayName && filterType && ['%', '#', 'EUR', 'USD'].includes(filterType)) {
    // TODO: replace with apiRef.autosizeColumns solution when @mui/x-data-grid-pro upgraded to v6
    // the current solution works approximately
    return {
      width: Math.max(
        Math.min(displayName.length * APPROX_CHAR_WIDTH, MAX_NUMERICAL_DEFAULT_COLUMN_WIDTH),
        MIN_NUMERICAL_DEFAULT_COLUMN_WIDTH,
      ),
    };
  }

  return { width: DEFAULT_COLUMN_WIDTH };
};

export const getColumn = ({
  // columnFilters,
  field,
  displayName,
  filterType,
  numericPrecision,
  sortable,
  columnMetadata,
}: ColumnDefinition): GridColDef => {
  return {
    ...getColumnWidth(field, displayName, filterType),
    field,
    headerName: displayName,
    renderHeader: () => (
      <CommonHeader
        headerName={displayName}
        columnMetadata={columnMetadata}
      />
    ),
    renderCell: (params: GridRenderCellParams) => (
      <CommonCell
        displayName={displayName}
        filterType={filterType}
        numericPrecision={numericPrecision}
        {...params}
      />
    ),
    sortable,
    // ...(columnFilters?.columns?.[field]?.supports_keyword && {
    //   type: 'singleSelect',
    //   valueOptions: (columnFilters?.columns?.[field]?.enum_values ?? [])
    //     .map((item) => ({ label: item, value: item })),
    // }),
  };
};

const chechIsCurrency = (unit: string) => CURRENCIES_SET.has(unit) || /^[A-Z]{3}$/.test(unit);

interface RowBasedColumnDefinition {
  field: string;
  displayName: string;
  width?: number;
  minWidth?: number;
  flex?: number;
  hideable?: boolean;
  pinnable?: boolean;
  sortable?: boolean;
  numericPrecision?: number;
  currency: Currency;
}

export const getRowBasedColumn = ({
  field,
  displayName,
  width,
  minWidth,
  flex,
  hideable,
  pinnable,
  sortable,
  numericPrecision,
}: RowBasedColumnDefinition): GridColDef => {
  // this returns type definition for row-based column which is for:
  // - legacy multiyear tab
  // - pivot table tab
  return {
    field,
    headerName: displayName,
    width,
    minWidth,
    flex,
    hideable,
    pinnable,
    sortable,
    renderCell: (params: GridRenderCellParams) => {
      const isTitle = typeof params['value'] === 'string';
      const isCurrency = chechIsCurrency(params.row.units);
      const isPercent = params.row.units == '%';
      const isCount = params.row.units == '#';
      const classes = 'truncate';

      if (isTitle) {
        return <span className={classes}>{params['value']}</span>;
      }

      let formattedValue;

      // We use numericPrecision as base of data formatting but intentionally diverge for certain units
      if (isCurrency) {
        // Intentionally use adaptive significant digits
        formattedValue = getCurrencyFormat(params['value'], params.row.units as Currency);
      } else if (isPercent) {
        // This is primarily revenue growth
        formattedValue = params['value'].toFixed(numericPrecision);
      } else if (isCount) {
        // This is primarily Total Employees
        formattedValue = params['value'].toLocaleString('en-US');
      } else if (params.row.units == 'Days') {
        // Days are up to 1 significant digit, with preference to render whole number if possible
        formattedValue = Number.isInteger(params['value']) ? params['value'] : params['value'].toFixed(1);
      } else {
        // Fallback is to use numeric precision but prefer integers if applicable
        formattedValue = Number.isInteger(params['value'])
          ? params['value']
          : params['value'].toFixed(numericPrecision);
      }

      return (
        <span
          className={classes}
          title={params['value']}
        >
          {formattedValue}
        </span>
      );
    },
  };
};

type TData = {
  columnMapper: IColumnMapper[];
  currency: string;
  defaultColumns: string[];
  columnFilters?: IColumFilters;
};

export const getColumns = ({ columnMapper, currency, defaultColumns, columnFilters }: TData) => {
  const groupedColumnMapper = keyBy(columnMapper, 'Backend Name');
  let columns = prepareColumns(columnMapper, []);

  // if column mapper is not yet fetched, fall back to defaultColumns
  if (columnMapper.length === 0) {
    columns = defaultColumns;
  }

  const COLUMNS: GridColDef[] = [
    {
      ...{ ...GRID_CHECKBOX_SELECTION_COL_DEF, headerName: 'Check' },
      renderHeader: () => <CheckboxHeader />,
      renderCell: (params) => <CheckboxCell {...params} />,
    },
    ...columns.map((column) => {
      const sortable = !!groupedColumnMapper[column]?.['SORTABLE_COLUMN'];
      const filterType = groupedColumnMapper?.[column]?.['NUMERIC_TYPE'] ?? null;
      const columnMetadata = groupedColumnMapper?.[column]?.['Column Metadata'];
      const numericPrecision = groupedColumnMapper?.[column]?.['NUMERIC_PRECISION'] || 0;

      let header = groupedColumnMapper[column]?.['Display Name'];

      if (column === 'self_financials_revenue___') {
        header = `${header} (${currency})`;
      }

      if (column === 'self_location_country___') {
        const data = getColumn({
          field: column,
          displayName: header,
          filterType,
          numericPrecision,
          sortable,
          columnMetadata,
        });

        return {
          ...data,
          type: 'singleSelect',
          valueOptions: (columnFilters?.columns?.self_location_country___?.enum_values ?? [])
            .map((item) => ({ label: item, value: item })),
        };
      }

      if (column === 'self_firmo_name___') {
        return {
          field: column,
          headerName: header,
          width: DEFAULT_COLUMN_WIDTH,
          renderHeader: () => (
            <CompanyHeader
              headerName={header}
              columnMetadata={columnMetadata}
            />
          ),
          renderCell: (params: GridRenderCellParams) => <CompanyCell {...params} />,
          sortable,
        };
      } else {
        return getColumn({
          // columnFilters,
          field: column,
          displayName: header,
          filterType,
          numericPrecision,
          sortable,
          columnMetadata,
        });
      }
    }),
  ];

  return COLUMNS;
};
