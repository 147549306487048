import React from 'react';
import { Skeleton } from '@mui/material';
import { range } from 'lodash';
import { GroupedMatchSearchItem } from '../GroupedMatchSearchItem';
import { useQueryGroupedMatchCompanySearch } from '@/hooks/queries/grouped-match-company-search/use-query-grouped-match-company-search';

interface GroupedMatchSearchResultsProps {
  searchText: string;
}

export const GroupedMatchSearchResults = ({ searchText }: GroupedMatchSearchResultsProps) => {
  const { data, isFetching, error } = useQueryGroupedMatchCompanySearch(searchText);

  if (isFetching)
    return (
      <div className="mt-6">
        {range(3).map((idx) => (
          <Skeleton
            key={idx}
            variant="rounded"
            className="bg-[#0000000f] rounded-2xl mb-4"
            height={80}
          />
        ))}
      </div>
    );

  if (error) return <div className="mt-6 text-sm font-normal text-[#484848]">Something went wrong</div>;

  if (data && data?.length === 0) return <div className="mt-6 text-sm font-normal text-[#484848]">Nothing found</div>;

  if (!data) return null;

  const [topResult, ...restResults] = data;

  return (
    <div className="mt-3">
      <div className="h-[1px] bg-[#F5F5F5]" />

      <p className=" text-xs font-medium text-[#666] my-6">TOP RESULT</p>

      <GroupedMatchSearchItem
        searchText={searchText}
        isTopResult
        searchResult={topResult}
      />

      {restResults.length ? (
        <div>
          <p className=" text-xs font-medium text-[#666] mb-6">MATCHED NAME, URL OR DESCRIPTION</p>
          {restResults.map((result) => (
            <GroupedMatchSearchItem
              key={result.bain_id}
              searchText={searchText}
              searchResult={result}
            />
          ))}
        </div>
      ) : null}
    </div>
  );
};
