import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { isEmpty } from 'lodash';
import { useSearchCompanies } from '../../../hooks/use-search-companies';
import { actions, QueryType } from '../../../slices/search';
import { useShallowSelector } from '../../../hooks/use-shallow-selector';
import { useModal } from '../../../hooks/use-modal';
import { MODALS, SEARCH_EVENTS } from '../../../constants';

import { SavedQueries } from '../SavedQueries';
import HistoryDropdown from '../HistoryDropdown';
import { SavedFilters } from '../SavedFilters';
import { DescriptionChip } from '../SavedFilters/DescriptionChip';
import { AdvancedSearchButton } from './Buttons/AdvancedSearchButton';
import { ClearSearchButton } from './Buttons/ClearSearchButton';
import SearchBar from './SearchBar';
import { ShowColumnsVisibilityPanelButton } from './Buttons/ShowColumnsVisibilityPanelButton';
import { FiltersDrawer } from '@/Components/Shared/FiltersDrawer';
import { HistoryQuery } from '@/types/history';
import { useQuerySavedSearches } from '@/hooks/queries/saved-search/use-query-saved-searches';

interface SearchProps {
  isCompaniesList?: boolean;
  isCompanyFinder?: boolean;
  isSemanticSearch?: boolean;
  onEnterKeyDown?: (text?: string) => void;
}

const Search = ({ isCompaniesList, isCompanyFinder, isSemanticSearch, onEnterKeyDown }: SearchProps) => {
  useQuerySavedSearches();

  const dispatch = useDispatch();
  const searchCompanies = useSearchCompanies();
  const { queryType, searchQueryTree } = useShallowSelector((state) => state.search);
  const [isHistoryOpened, setIsHistoryOpened] = useState(false);
  const { isOpen: isSemanticSearchLoadingDialogOpen } = useModal(MODALS.SEMANTIC_SEARCH_LOADING_DIALOG);

  const isKeywordQuery = queryType === QueryType.KEYWORD;
  const isSmartQuery = queryType === QueryType.SMART;

  const handleHistoryToggle = (isOpened: boolean) => {
    setIsHistoryOpened(isOpened);
  };

  const handleHistorySearch = (query: HistoryQuery) => {
    dispatch(actions.setQueryType(query.queryType));
    dispatch(actions.setSearchQuery(''));
    dispatch(actions.setSearchText(query.isLucene ? '' : query.searchText));
    searchCompanies({ ...query, eventName: SEARCH_EVENTS.HISTORY }, { useStateFilters: true });
  };

  return (
    <div className="flex flex-col">
      {isSemanticSearch ? null : (
        <div className="flex w-full gap-3 ml-0">
          <SearchBar
            isCompanyFinder={isCompanyFinder}
            isHistoryOpened={isHistoryOpened}
            onEnterKeyDown={onEnterKeyDown}
          />

          {isCompanyFinder ? null : (
            <>
              {!isCompaniesList && (
                <HistoryDropdown
                  onDropdownToggle={handleHistoryToggle}
                  onQuerySelect={handleHistorySearch}
                />
              )}

              <FiltersDrawer />

              {isKeywordQuery ? <AdvancedSearchButton /> : null}

              <ShowColumnsVisibilityPanelButton />

              <ClearSearchButton />
            </>
          )}
        </div>
      )}

      <div data-testid="search-saved-filters">
        {isSemanticSearchLoadingDialogOpen ? null : (
          <div
            className="flex flex-wrap max-h-[230px] overflow-y-auto mt-3"
            data-testid={!isEmpty(searchQueryTree) ? 'search-saved-queries' : undefined}
          >
            <DescriptionChip />
            <SavedFilters />
            {(isKeywordQuery || isSmartQuery) && <SavedQueries alwaysSeparateKeywords={false} />}
          </div>
        )}
      </div>
    </div>
  );
};

export default Search;
