import React, { useMemo, useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, ChartData, ChartOptions, Plugin } from 'chart.js';
import { isNaN } from 'lodash';
import 'chart.js/auto';

import { DownloadChartImageButton } from '../common/DownloadChartImageButton';
import { DownloadCsvButton } from '../../Buttons/DownloadCsvButton';
import { ChartConfigType, ChartDataType, Currency } from '@/types';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { getAxisTick } from '@/Utils/charts';

interface IProps {
  config: ChartConfigType;
  data: ChartDataType[];
  handleDownloadXlsx: () => void;
  handleDownloadImage: (ref: React.RefObject<Chart>) => void;
}

const getOptions = (config: ChartConfigType, currency: Currency): ChartOptions<'bar'> => {
  const yAxes = config.METADATA?.Y_AXIS ?? [];
  const [left, right] = ['LEFT', 'RIGHT'].map(pos =>
    yAxes.find(item => item['AXIS-POSITION'] === pos),
  );
  const leftAxisTitle = yAxes.filter(item => item['AXIS-POSITION'] === 'LEFT').map(item => item.TITLE).join(' / ');

  const createAxis = (position: 'left' | 'right', axis?: typeof left) => ({
    type: 'linear' as const,
    position,
    title: {
      display: true,
      text: position === 'left' ? leftAxisTitle : axis?.TITLE,
      font: { family: 'Graphik' },
    },
    ticks: {
      callback: (value: unknown) => getAxisTick({
        value: value as string,
        yAxisType: axis?.FORMAT,
        currency,
      }),
    },
    grid: {
      display: position === 'left',
      color: position === 'left' ? '#ddd' : undefined,
    },
    ...(position === 'left' && {
      border: { dash: [2, 2] },
      beginAtZero: true,
    }),
  });

  return {
    maintainAspectRatio: false,
    responsive: true,
    interaction: { mode: 'index', intersect: false },
    scales: {
      x: { grid: { display: false } },
      left: createAxis('left', left),
      right: createAxis('right', right),
    },
    plugins: {
      legend: { position: 'bottom', align: 'start', reverse: true },
      tooltip: {
        filter: ({ raw }) => isNaN(Number(raw)) || (raw as number) > 0,
      },
    },
  };
};

const plugin: Plugin<'bar'> = {
  id: 'customCanvasBackgroundColor',
  beforeDraw: (chart) => {
    const { ctx } = chart;

    ctx.save();
    ctx.globalCompositeOperation = 'destination-over';
    ctx.fillStyle = '#fff';
    ctx.fillRect(0, 0, chart.width, chart.height);
    ctx.restore();
  },
};

export const LineOverBarChart = ({ data, config, handleDownloadXlsx, handleDownloadImage }: IProps) => {
  const ref = useRef<Chart<'bar'>>(null);
  const currency = useShallowSelector((state) => state.config.currency);
  const options = useMemo(() => getOptions(config, currency), [config, currency]);
  const yAxes = config.METADATA?.Y_AXIS ?? [];
  const xAxis = config.METADATA?.X_AXIS ?? [];

  const chartData: ChartData<'bar'> = {
    labels: data.map((item) => item[xAxis[0]?.FIELD_NAME ?? '']),
    // @ts-ignore
    datasets: yAxes.map((yItem) => {
      const chartType = yItem.CHART_TYPE?.toLowerCase();
      const isBar = chartType === 'bar';

      return {
        label: yItem.TITLE,
        type: chartType,
        data: data.map((item) => (item[yItem.FIELD_NAME] ? Number(item[yItem.FIELD_NAME]) : null)),
        ...(isBar && {
          backgroundColor: yItem.STYLE.COLOR,
          skipNull: true,
        }),
        ...(!isBar && {
          borderColor: yItem.STYLE.COLOR,
          backgroundColor: yItem.STYLE.COLOR,
          pointRadius: 0,
          pointHoverBackgroundColor: '#0484e7',
          pointHoverBorderColor: '#fff',
          spanGaps: true,
        }),
        yAxisID: yItem['AXIS-POSITION'].toLowerCase(),
        order: isBar ? 1 : 0,
      };
    }),
  };

  return (
    <div
      className="bg-white rounded"
      style={{ boxShadow: '0px 1px 3px 0px rgba(46, 48, 48, 0.14)' }}
    >
      <div className="flex items-center justify-between px-6 py-3">
        <span className="text-[#484848] text-sm font-semibold">{config.TITLE}</span>

        <div className="flex items-center gap-4">
          <DownloadChartImageButton
            onClick={() => handleDownloadImage(ref)}
            dataTestId="chart-download-image"
            id={`${config.SLUG}-chart-download-image`}
          />

          <DownloadCsvButton
            onClick={handleDownloadXlsx}
            dataTestId="chart-download-xlsx"
            id={`${config.SLUG}-chart-download-xlsx`}
          />
        </div>
      </div>

      <hr className="text-[#ddd] mb-6" />

      <div className="h-[400px] px-6 pb-4">
        <Bar
          ref={ref}
          data={chartData}
          options={options}
          plugins={[plugin]}
        />
      </div>
    </div>
  );
};
