import { CURRENCIES } from '@/constants';
import { ChartConfigType, Currency } from '@/types';
import { Tab as TabType } from '@/Components/CompanyResearch/CompanyProfile/types';
import { numberFormatterCustom } from '@/Components/Shared/common';

type AxisFormatType = { value: string, yAxisType?: string | null; currency: Currency };

export const getAxisTick = ({ value, yAxisType, currency }: AxisFormatType) => {
  switch (yAxisType) {
    case 'currency':
      return `${CURRENCIES[currency].label}${numberFormatterCustom(Number(value))}`
    case 'numeric':
      return numberFormatterCustom(Number(value));
    case 'percent':
      return value;

    default:
      return numberFormatterCustom(Number(value));
  }
};

export const getYaxisLabel = ({ yAxisType, currency }: { yAxisType?: string | null; currency: Currency }) => {
  return yAxisType === 'currency' ? CURRENCIES[currency].label : '';
};

export const getTabsWithCharts = (tabs: TabType[], chartConfigs: ChartConfigType[]) => {
  const sections = Array.from(new Set(chartConfigs.map((config) => config.SECTION)));

  const modifiedTabs = tabs.map((tab) => {
    if (sections.includes(tab.label)) {
      return {
        ...tab,
        components: [{ component: 'Charts' }, ...(tab.components ?? [])],
      };
    }

    return tab;
  });

  return modifiedTabs;
};
