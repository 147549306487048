import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { DATA_SOURCE_ID } from '@/constants';

interface IProps {
  columnId: DATA_SOURCE_ID;
  title?: string;
}

export default function SourceLogo({ columnId, title }: IProps) {
  return title ? (
    <Tooltip
      title={title}
      enterDelay={300}
    >
      <div
        className="w-[24px] h-[24px] bg-no-repeat bg-cover bg-center rounded"
        style={{ backgroundImage: `url(/data-sources/${columnId}.png)` }}
      />
    </Tooltip>
  ) : (
    <div
      className="w-[24px] h-[24px] bg-no-repeat bg-cover bg-center rounded"
      style={{ backgroundImage: `url(/data-sources/${columnId}.png)` }}
    />
  );
}
