import React, { useState } from 'react';

import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import { GroupedMatchSearchResults } from './GroupedMatchSearchResults';
import Search from '@/Components/CompanyResearch/Search/Search';
import { NotificationMessage } from '@/Components/Shared/Notifications/NotificationMessage';
import { WorkflowDialogFooter } from '@/Components/Workflows/Common/WorkflowDialogFooter';
import { HOME_PAGE_SLUG } from '@/constants';

const CompanyFinder = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { push } = useHistory();

  const [searchText, setSearchText] = useState<string | undefined>();

  const onEnterKeyDown = (text?: string) => {
    if (!text) {
      setSearchText(undefined);

      return;
    }

    if (text.length < 3) {
      enqueueSnackbar(<NotificationMessage title="Please provide at least 3 characters" />, {
        variant: 'warning',
      });

      return;
    }

    setSearchText(text);
  };

  return (
    <div className="w-full h-[calc(100vh-48px)] flex flex-col overflow-y-scroll">
      <div className="w-1/3 flex flex-col justify-center mt-24 m-auto">
        <div className="mb-2 text-2xl font-medium text-[#484848]">What company are you looking for?</div>
        <div className="mb-12 text-sm font-normal text-[#484848]">
          Our company profiles contain detailed financial, people & web traffic insights.
        </div>

        <div>
          <Search
            isCompanyFinder
            onEnterKeyDown={onEnterKeyDown}
          />
        </div>
        <div className="flex-grow">{searchText ? <GroupedMatchSearchResults searchText={searchText} /> : null}</div>
      </div>
      <WorkflowDialogFooter
        onBackButtonClick={() => push(HOME_PAGE_SLUG)}
        isLoading={false}
        progress={searchText ? 70 : 30}
      />
    </div>
  );
};

export default CompanyFinder;
