import React from 'react';
import Button from '@mui/material/Button';

export const COLUMNS_VISIBILITY_PANEL_BUTTON_ID = 'toggle-columns-visibility-panel-button';

export const ShowColumnsVisibilityPanelButton = () => {
  const onClick = () => {
    const nativeColumnsVisibilityPanelButton = document.getElementById(COLUMNS_VISIBILITY_PANEL_BUTTON_ID);

    if (nativeColumnsVisibilityPanelButton) {
      nativeColumnsVisibilityPanelButton.click();
    } else {
      window.alert('There is no table context');
    }
  };

  return (
    <Button
      className="px-2 py-2 border-bluegray-900 text-bluegray-900 border border-solid hover:bg-bluegray-900 hover:text-white font-graphik text-xs h-[36px]"
      onClick={onClick}
      id="pick-columns-button"
    >
      Pick columns
    </Button>
  );
};
