import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import cn from 'classnames';

import { useSnackbar } from 'notistack';
import { useSearchCompanies } from '../../../../hooks/use-search-companies';
import { IconSearch } from '../../../Icons/IconSearch';
import { actions, QueryType } from '../../../../slices/search';
import { useShallowSelector } from '../../../../hooks/use-shallow-selector';
import { useModal } from '../../../../hooks/use-modal';
import { DATA_SOURCE_ID, DATA_SOURCE_IDS, HIDE_SIMILAR_COMPANIES, MODALS, ROUTES } from '../../../../constants';

import { Loader } from '../../../Shared/Loader/Loader';
import SearchTypeDropdown from '../../SearchTypeDropdown';
import useAutocompleteCompanySearch from './../hooks/use-autocomplete-company-search';
import CheckSingleQuotesNotification from './../CheckSingleQuotesNotification/CheckSingleQuotesNotification';
import { validateSingleQuotationMarks } from './../utils/validate-single-quotation-marks';
import { BetaTag } from '@/Components/Shared/BetaTag/BetaTag';
import { useTableLoading } from '@/hooks/table/use-table-loading';
import { useQuerySavedSearches } from '@/hooks/queries/saved-search/use-query-saved-searches';
import SourceLogo from '@/Components/Shared/SourceLogo/SourceLogo';
import { IResponseCompanySearchItem } from '@/types/api/company-search';

interface SearchProps {
  isCompanyFinder?: boolean;
  isSemanticSearch?: boolean;
  isHistoryOpened: boolean;
  onEnterKeyDown?: (text?: string) => void;
}

const SearchBar = ({ isCompanyFinder, isSemanticSearch, isHistoryOpened, onEnterKeyDown }: SearchProps) => {
  useQuerySavedSearches();

  const { push } = useHistory();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const searchCompanies = useSearchCompanies();
  const inputRef = useRef(null);
  const [queryTypeAnchorEl, setQueryTypeAnchorEl] = useState(null);
  const { hasSearchError, searchText, hasLuceneGrammarError, queryType } = useShallowSelector((state) => state.search);
  const { isOpen: isAdvancedSearchDialogOpen } = useModal(MODALS.ADVANCED_SEARCH);
  const [isFocused, setIsFocused] = useState(false);
  const { isMatchCompanySearchLoading, matchCompanyTypeAheadList } = useAutocompleteCompanySearch({
    isFocused,
    searchText,
  });
  const isTableLoading = useTableLoading();
  const isSearchIconLoading = isMatchCompanySearchLoading || isTableLoading;

  const isKeywordQuery = queryType === QueryType.KEYWORD;
  const isMatchQuery = queryType === QueryType.MATCH;
  const isSmartQuery = queryType === QueryType.SMART;

  const handleClearSearch = () => {
    dispatch(actions.setHasSearchError(false));
    dispatch(actions.setSearchText(''));
    onEnterKeyDown?.(undefined);
  };

  useEffect(() => {
    dispatch(actions.setHasSearchError(false));
  }, [dispatch]);

  const afterCheckSearch = (searchString: string) => {
    closeSnackbar();

    searchCompanies({ searchText: searchString, queryType });
    setIsFocused(false);

    if (isKeywordQuery) {
      dispatch(actions.setBackupSearchText(searchString));
    }
  };

  const search = async (searchTextValue: string) => {
    if (!isKeywordQuery) {
      afterCheckSearch(searchTextValue);

      return;
    }

    const { valid, wrongTextParts, standardizedText } = validateSingleQuotationMarks(searchTextValue);

    if (!valid && wrongTextParts?.length) {
      closeSnackbar();
      enqueueSnackbar(
        <CheckSingleQuotesNotification
          standardizedText={standardizedText}
          wrongTextParts={wrongTextParts}
          onYes={() => afterCheckSearch(standardizedText)}
          onNo={() => afterCheckSearch(standardizedText.replaceAll(/'/g, '"'))}
        />,
        {
          variant: 'info',
          persist: true,
        },
      );

      return;
    }

    afterCheckSearch(standardizedText);
  };

  const handleInputChange = (event: React.SyntheticEvent<Element, Event>, value: string) => {
    if (event) {
      dispatch(actions.setSearchText(value));
    }

    setIsFocused(event?.type === 'change' && value.length >= 3);

    if (hasSearchError) {
      dispatch(actions.setHasSearchError(false));
    }
  };

  const handleOnChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: string | IResponseCompanySearchItem | null,
  ) => {
    if (value === null) {
      return;
    }

    if (event.type === 'click') {
      // @ts-ignore
      const idx = +event.target.dataset?.optionIndex;
      const isUniq = idx !== 0;

      searchCompanies({
        searchText: typeof value === 'string' ? value : value.self_firmo_name___,
        queryType,
        isUniq,
      });

      setIsFocused(false);

      if (isCompanyFinder) {
        push(ROUTES.CUSTOM_SEARCH);
      }
    }
  };

  const handleEnter: React.KeyboardEventHandler<HTMLDivElement> = (event) => {
    const isEnter = event?.key === 'Enter';

    if (isEnter) {
      event.preventDefault();
    }

    if (isEnter && (event.target as HTMLInputElement).value.length > 0) {
      if (isCompanyFinder) {
        onEnterKeyDown?.(searchText);
        setIsFocused(false);

        return;
      }

      search(searchText);
    }
  };

  const handleQueryTypePickerToggle = () => {
    setIsFocused(false);
    setQueryTypeAnchorEl((prevState) => (prevState ? null : inputRef?.current));
  };

  const getPlaceholderText = () => {
    if (isSemanticSearch) return 'Add keywords e.g. Solar, renewable or energy...';
    else if (isKeywordQuery)
      return 'Search for companies using keywords, for example: “solar panels” OR “renewable energy”';
    else if (isMatchQuery) return 'Search for companies using a name, URL or description...';
    else if (isSmartQuery) return 'Give me a list of companies that ... (type here)';
  };

  const hasError = !isAdvancedSearchDialogOpen && (hasSearchError || hasLuceneGrammarError);

  const matchDropdownOptions = useMemo(
    () => [searchText, ...matchCompanyTypeAheadList],
    [matchCompanyTypeAheadList, searchText],
  );

  const renderSearchIcon = () => {
    if (isSemanticSearch) return <span className="w-2" />;

    return isSearchIconLoading ? (
      <div className="mr-1 ml-2.5">
        <Loader />
      </div>
    ) : (
      <Tooltip title="Search">
        <IconButton
          onClick={() => search(searchText)}
          className="ml-1 mb-[1px]"
          size="small"
          data-testid="search-icon"
        >
          <IconSearch />
        </IconButton>
      </Tooltip>
    );
  };

  const getFieldBorderClass = () => {
    if (hasError) return 'border-[#D63333]';
    if (isSemanticSearch) return 'border-[#ddd]';

    return 'border-bluegray-900';
  };

  return (
    <Autocomplete
      open={isFocused && isMatchQuery && searchText.length > 2 && !isHistoryOpened && !queryTypeAnchorEl}
      id="company-name"
      freeSolo={true}
      inputValue={searchText}
      className={cn({
        'w-[790px]': !isCompanyFinder && !isSemanticSearch,
        'w-full': isCompanyFinder || isSemanticSearch,
      })}
      options={matchDropdownOptions}
      onInputChange={handleInputChange}
      onChange={handleOnChange}
      onKeyDown={handleEnter}
      onFocus={(event) => {
        if (event.relatedTarget) return;

        setIsFocused(!isHistoryOpened || !queryTypeAnchorEl);
      }}
      onBlur={() => setIsFocused(false)}
      autoHighlight
      getOptionKey={(option) => (typeof option === 'string' ? option : option.self_firmo_name___)}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.self_firmo_name___)}
      filterOptions={(x) => x}
      renderOption={(props, option) => {
        // eslint-disable-next-line react/prop-types
        const { id } = props;
        const isPlaceholderOption = id === 'company-name-option-0';
        const isFirstOptionString = id === 'company-name-option-1';

        if (isPlaceholderOption) {
          const placeholder = option as string;

          return (
            <li
              {...props}
              className={'text-sm font-normal cursor-pointer text-[#999] py-2.5 mx-2.5 border-b-[#ddd] border-b'}
              key={id}
            >
              <span>{`"${placeholder}" Press Enter to view all results`}</span>
            </li>
          );
        }

        //TODO: adapt design (no figma provided)
        if (isFirstOptionString) {
          const topResult = option as IResponseCompanySearchItem;

          return (
            <li
              {...props}
              className="text-sm font-normal cursor-pointer py-2.5 mx-2.5 hover:bg-[#fff]"
              key={id}
            >
              <div className="mt-3 mb-5 uppercase text-xs font-medium">Top result</div>
              <div className="p-4 shadow-md rounded border flex gap-5 justify-between items-center">
                <div className="flex flex-col gap-2">
                  <span className="font-medium">{topResult?.self_firmo_name___}</span>
                  {topResult?.self_sector_industry___ ? <span>{topResult.self_sector_industry___}</span> : null}
                  {topResult?.id_matches &&
                    Object.keys(topResult?.id_matches).map((sourceColumn) => (
                      <div
                        key={sourceColumn}
                        className="flex flex-row gap-2 text-gray items-center"
                      >
                        <SourceLogo columnId={sourceColumn as DATA_SOURCE_ID} />
                        <div>
                          <span>
                            {DATA_SOURCE_IDS[sourceColumn as DATA_SOURCE_ID]}:&nbsp;
                            {topResult?.id_matches?.[sourceColumn as DATA_SOURCE_ID]}
                          </span>
                        </div>
                      </div>
                    ))}
                </div>

                {topResult?.self_firmo_description___ && !HIDE_SIMILAR_COMPANIES ? (
                  <Button
                    variant="outlined"
                    className="text-[#2e3f4c] border-[#2e3f4c] py-1 px-3 w-[158px]"
                    onClick={(event) => {
                      event.stopPropagation();

                      window.open(`${ROUTES.COMPANY_PROFILE}/${topResult?.bain_id}/similar-companies`, '_blank');
                    }}
                  >
                    <span className="mr-3">View similar</span>
                    <BetaTag />
                  </Button>
                ) : null}
              </div>
              {matchCompanyTypeAheadList.length > 2 ? (
                <div className="mt-6 mb-1 uppercase text-xs font-medium">More results</div>
              ) : null}
            </li>
          );
        }

        // for 2nd and rest of options, let's use just self_firmo_name__
        const optionString = (option as IResponseCompanySearchItem).self_firmo_name___;
        const isOptionString = typeof optionString === 'string';
        const { length } = searchText;
        const searchTextIndex = isOptionString ? optionString.toLowerCase().indexOf(searchText.toLowerCase()) : 0;

        const result = isOptionString
          ? [
              optionString.substring(0, searchTextIndex),
              optionString.substring(searchTextIndex, searchTextIndex + length),
              optionString.substring(searchTextIndex + length, Infinity),
            ]
          : [];

        return (
          <li
            {...props}
            className={'text-sm font-normal cursor-pointer px-2.5 py-1 hover:bg-[#0000000a]'}
            key={id}
          >
            <span>{result[0]}</span>
            <b>{result[1]}</b>
            <span>{result[2]}</span>
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          ref={inputRef}
          error={hasError}
          id="name-query"
          autoFocus={isCompanyFinder}
          data-testid="search-field"
          placeholder={getPlaceholderText()}
          variant="outlined"
          multiline
          InputLabelProps={{ style: { fontSize: 14 } }}
          inputProps={{
            ...params.inputProps,
            ...(isCompanyFinder && { style: { margin: '6px 0' } }),
            autoComplete: 'off', // disable autocomplete and autofill
          }}
          InputProps={{
            ...params.InputProps,
            style: { padding: '7px 0' },
            classes: {
              notchedOutline: getFieldBorderClass(),
            },
            startAdornment: (
              <>
                {isCompanyFinder || isSemanticSearch ? null : (
                  <>
                    <InputAdornment
                      position="start"
                      className="ml-2 mr-1 min-h-[36px]"
                    >
                      <SearchTypeDropdown
                        anchorElement={queryTypeAnchorEl}
                        toggleDropdown={() => handleQueryTypePickerToggle()}
                        closeDropdown={() => setQueryTypeAnchorEl(null)}
                      />
                    </InputAdornment>

                    <Divider
                      orientation="vertical"
                      flexItem
                      className={hasError ? 'bg-[#D63333]' : 'bg-bluegray-900'}
                    />
                  </>
                )}

                {renderSearchIcon()}
              </>
            ),
            endAdornment: (
              <InputAdornment
                position="end"
                className="m-0 mr-2"
              >
                {isFocused && searchText && (
                  <IconButton
                    onClick={handleClearSearch}
                    size="small"
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
        />
      )}
      classes={{
        popper: 'mt-1 drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)]',
        inputRoot: 'p-0 min-h-[36px]',
      }}
    />
  );
};

export default SearchBar;
